.index-slide-show-v1 {
	position: relative;

	margin-top: var(--page-margin-top);


	.owl-carousel {
		z-index: unset;
	}


	.index-slide {
		height: var(--index-slide-show-height);
		margin-top: var(--index-slide-show-margin-top);

		.item {
			height: var(--index-slide-show-height);
			overflow: hidden;

			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}

		.slide-item {
			height: var(--index-slide-show-height);
			min-height: var(--index-slide-show-height);
			position: relative;
			background-repeat: no-repeat;
			background-position: top center;
			background-size: cover;
		}

		.picture {
			background-size: cover;
			background-repeat: no-repeat;
			width: 100%;
			height: 424px;
			display: block;
			background-position: center;

			@media (max-width:767px) {
				height: 214px;
			}


			&.slide1 {
				background-image: url(RESOURCE/img/slider-1.jpg);
			}

			&.slide2 {
				background-image: url(RESOURCE/img/slider-2.jpg);
			}

			&.slide3 {
				background-image: url(RESOURCE/img/slider-3.jpg);
			}

			&.slide4 {
				background-image: url(RESOURCE/img/slider-4.jpg);
			}

			&.slide5 {
				background-image: url(RESOURCE/img/slider-5.jpg);
			}

			&.slide6 {
				background-image: url(RESOURCE/img/slider-6.jpg);
			}

			/*height: var(--index-slide-show-height);
			min-height: var(--index-slide-show-height);

			@media (max-height: 600px) {
				min-height: 680px;

			}

			.img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}*/

		}

		button {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			font-size: 80px !important;
			margin-top: -30px;
			color: #fff !important;

			&:before {
				content: "";
				border: solid #fff;
				border-width: 0 3px 3px 0;
				display: inline-block;
				padding: 6px;
			}

			span {
				display: none;
			}

			@media (max-width: 767px) {
				margin-top: 0;
				font-size: 40px !important;

			}

			&.owl-prev {
				left: 30px;

				&:before {
					transform: rotate(135deg);
					-webkit-transform: rotate(135deg);
				}

				@media (max-width: 767px) {
					left: 15px;

				}
			}

			&.owl-next {
				right: 30px;

				&:before {
					transform: rotate(-45deg);
					-webkit-transform: rotate(-45deg);
				}

				@media (max-width: 767px) {
					right: 15px;

				}

			}
		}
	}

	.carousel-item {
		height: var(--index-slide-show-height);
		overflow: hidden;

		@media (max-height: 600px) {
			min-height: 680px;

		}
	}

	.carousel-item img {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}

	.slide1 {
		background-image: url(../img/slide1-1980.jpg);
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}

	.hero-header {
		display: none;
		position: absolute;
		top: 29vh;
		font-family: 'Poppins', cursive;
		width: 100%;
		z-index: 3;
		padding: 0 5px;

		@media (max-height: 600px) {
			top: 200px;

		}

		.hero-text {
			text-align: center;
			width: 100%;

			.hero-headline {
				width: 100%;
			}

			h1 {
				font-family: var(--hero-header-font-family);
				font-size: 5vw;
				color: #fff;
				text-shadow: 0 0.1rem 1rem rgba(0, 0, 0, .5);

				font-weight: 400;
				margin-bottom: 0px;

				@media (min-width: 1600px) {
					font-size: 80px;
				}

				@media (max-width: 1140px) {
					font-size: 57px;
				}

				@media (max-width: 992px) {
					font-size: 4rem;
					line-height: 4rem;
				}

				@media (max-width: 576px) {
					font-size: 3rem;
					line-height: 3rem;
				}

				@media (max-height: 600px) {
					font-size: 2.75rem;
					line-height: 2.75rem;

				}

			}

			.hero-subtitle {
				font-family: var(--hero-header-sub-title-font-family);
				font-size: 2.5rem;
				color: #fff;
				text-shadow: 0 0.1rem 1rem rgba(0, 0, 0, .5);

				@media (min-width: 1600px) {
					font-size: 2.5rem;
				}

				@media (max-width: 1140px) {
					font-size: 2.0rem;
				}

				@media (max-width: 992px) {
					font-size: 2.0rem;
				}

				@media (max-width: 575px) {
					font-size: 1.5rem;
				}
			}
		}

	}
}