.regionen-v1 {
	margin-top: var(--page-margin-top);

	p {
		text-align: left;

		img {
			margin: 0 auto;
			display: block;
		}

	}
}