.unit-feedbacks-v1 {
	.widget-header {
		display: flex;
		align-items: center;

		.rating-data {
			background: var(--color-primary);
			padding: 3px 5px;
			border-radius: 5px;
			font-size: 18px;
			display: flex;
			align-items: center;
			padding-top: 0;

			i {
				font-size: 16px;
				margin-left: 2px;
			}
		}
	}

	.ptitle {
		display: inline-block;
	}

	.ratings {
		display: flex;
		flex-wrap: wrap;
		position: relative;

		@media (min-width:768px) {
			padding-left: 0;
		}

		.rating {
			margin: 0 30px 15px 0;

			&.first-rat {
				@media (max-width:767px) {
					width: 100%;
				}

				@media (min-width:768px) {
					position: absolute;
					left: 0;
					top: 0;
				}

			}

			.fa {
				color: var(--color-primary)
			}
		}
	}

	.feedbacks {

		clear: left;
		padding-top: 15px;

		.ratings {
			padding-left: 0;
		}

		.feedback {
			border-left: 3px solid var(--color-grey-normal);
			background-color: var(--color-grey-light);
			padding: 10px;
			font-style: italic;
			margin-bottom: 30px;

			.rating {
				font-size: var(--font-size-sm);
			}

			.guest-text {
				clear: left;
				padding-top: 10px;
				font-size: var(--font-size-md);
			}

			.author {
				font-size: var(--font-size-sm);
			}

			.comment {
				padding-left: 10px;
				border-left: 3px dotted var(--color-red);
				font-size: var(--font-size-md);
			}

			.agency {
				padding-bottom: 10px;
			}
		}
	}
}