.navbar-v1.affix,
.navbar-v1 {

	position: fixed;
	width: 100%;
	top: 0;
	border-width: 0 0 1px;
	padding: 0;
	z-index: 50;
	box-shadow: 0 3px 3px -3px grey;
	background-color: var(--nav-bg-color);

	@media (max-width:1200px) {
		position: absolute;
	}

	.input-search-unit {
		max-width: 280px;

		@media (max-width:1200px) {
			max-width: 100%;

		}

		@media (max-width:1200px) {
			margin-bottom: 5px;
		}

		.form-control {
			padding: 5px 10px;
			width: 200px;

		}

		.form-control::placeholder {
			font-size: 0.875rem;
			color: #aaa;
			font-style: italic;
		}

		.btn {
			border-radius: 0 5px 5px 0;
			padding: 0px 15px;
		}
	}

	.top-nav {
		font-size: var(--top-nav-font-size);
		font-family: var(--bodyfont);
		background-color: var(--top-nav-bg-color);
		padding-top: 3px;
		padding-bottom: 3px;


		.header-logo {

			padding-right: 15px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;
			position: relative;

			@media (max-width:1200px) {
				flex-direction: column;
				padding-right: 0;
				padding: 0;
			}

			&:after {
				@media (min-width:1201px) {
					content: "";
					height: 50px;
					width: 1px;
					background-color: #325881;
					position: absolute;
					right: 0;
				}
			}

			.logo1 {
				max-width: 247px;
				padding: 5px;
			}

			.logo2 {
				max-width: 207px;
				padding: 5px;

			}


		}

		.top-nav-item {
			padding: 5px;

			ul {
				padding: 0;
				margin: 0;
				display: inline-flex;
				align-items: center;
				flex-wrap: wrap;
				justify-content: flex-end;

				li {
					display: inline-block;
					position: relative;
					font-size: var(--top-nav-font-size);
					color: var(--top-nav-font-color);

					@media (max-width:1200px) {
						display: block;
					}

					&.input-search-unit {
						@media (max-width:1200px) {
							display: none;
						}
					}

					a {
						color: var(--top-nav-font-color);
						font-size: var(--top-nav-font-size);
						text-decoration: none;
						padding: 5px 15px;

						@media (max-width:550px) {
							padding: 5px 4px;
						}

						&:hover {
							color: var(--top-nav-font-color-hover)
						}

						i.fa {
							font-size: var(--top-nav-icon-size);
							margin-right: 5px;
						}
					}
				}
			}

			.fav-count {

				.sub-count {
					-moz-border-radius: 50%;
					-webkit-border-radius: 50%;
					border-radius: 50%;
					display: block;
					position: absolute;
					top: -5px;
					left: 2px;
					width: 18px;
					height: 18px;
					background: var(--color-secondary);
					color: var(--color-white);
					text-align: center;
					font-size: 12px;
					line-height: 18px;

					@media (max-width:767px) {
						left: 0;
					}
				}
			}
		}
	}

	.nav-menu-bl {
		background-color: #c6e3fa;
		padding: 5px 0;
	}

	.header-main {
		display: flex;
		align-items: center;
		justify-content: center;

		@media(max-width:1200px) {
			display: block;
		}

		.header-logo {
			position: absolute;
			top: 30px;
			z-index: 20;

			.logo {
				width: 230px;
				height: auto;

				@media (max-width:1200px) {
					width: 230px;
				}

				@media (max-width:768px) {
					width: 200px;
				}
			}

		}

		.main-menu {
			/*float: right;*/

			@media (max-width:1200px) {
				position: absolute;
				left: 0;
				top: 100%;
				background-color: var(--main-nav-mob-menu-bg-color);
			}

			>ul {
				padding: 0;
				margin: 0;

				>li {
					float: left;
					display: inline-block;
					margin-right: 25px;

					@media (max-width:1200px) {
						display: block;
						width: 100%;
					}

					&:last-child {
						margin-right: 0;
					}

					>a {
						line-height: 1;
						font-weight: 400;
						color: #325881;
						font-size: 18px;
						padding: 9px 13px 10px;
						display: block;

						@media all and (min-width: 993px) and (max-width:1199px) {
							font-size: 14px;
							padding-left: 11px;
							padding-right: 11px;
						}

						@media (max-width:1199px) {
							font-size: var(--main-nav-font-size);
							color: var(--color-white);
						}

						@media (max-width:1200px) {
							padding: 10px 0;
							text-transform: capitalize;
							font-size: var(--main-nav-font-size);
							color: var(--main-nav-mob-font-color-hover);
						}


						&:hover {

							text-decoration: underline;
						}
					}

					&:hover {
						>a {
							color: var(--main-nav-font-color-hover);

							text-decoration: none;

						}

						.dropdown-toggle {
							color: var(--main-nav-font-color-hover);
						}

						@media (min-width:1200px) {
							.dropdown-menu {
								display: block;
							}
						}

						.separator-line {
							color: #000 !important;
						}

					}


					&.dropdown {

						cursor: pointer;

						.dropdown-backdrop {
							display: none;
						}


						&.show {
							background-color: var(--main-nav-bg-hover);

							a {
								color: var(--main-nav-font-color-hover);
							}
						}
					}

					.dropdown-toggle.separator-line {
						position: absolute;
						top: 4px;
						right: 0px;
						color: #325881;

						@media all and (min-width: 993px) and (max-width: 1199px) {
							right: 0;
						}

						@media (max-width:992px) {
							color: #fff;
							right: 10px;
							top: 3px;
							font-size: 20px;
							min-height: 25px;
						}

						&.full-arrow {
							@media (max-width:992px) {
								width: 100%;
								text-align: right;
							}
						}

						/*&.separator-line {
							border-left: 1px solid #fff;
							height: 100%;
							top: 0;
							bottom: 0;
							line-height: 48px;
							padding-left: 5px;
							right: 4px;

							@media (max-width:1200px) {
								line-height: 25px;
								height: 25px;
								top: auto;
								padding-left: 10px;
							}

							@media (max-width:767px) {
								border-left: 0;
							}
						}*/
					}



				}
			}

			.btn {
				@media (max-width:340px) {
					font-size: 12px;
					padding: 8px 8px;
				}
			}
		}
	}

	.dropdown-menu {
		border-radius: 0;
		border: none;
		box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
		min-width: 300px;
		margin-top: 0px;
		background-color: #c6e3fa;
		padding: 0;

		.sub-nav {
			a {
				&:hover {
					background-color: var(--color-primary-light);
				}
			}
		}

		&.mega-menu {
			@media (min-width:1201px) {
				width: 530px;
			}
		}

	}

	.sub-nav {
		@media (min-width:1200px) {
			max-height: 500px;
			overflow-y: auto;
			overflow-x: hidden;
		}

		ul {
			list-style-type: none;


			li {

				a {
					padding: 5px 10px;
					color: var(--main-sub-font-color) !important;
					display: block;
					font-size: 15px;

					@media (max-width:992px) {
						padding: 10px;
						font-weight: 400;
						text-transform: initial;
					}

					&:hover {

						text-decoration: none;

					}
				}

			}
		}


	}

	.icon {
		display: none;
		position: absolute;
		right: 10px;
		text-decoration: none;
		color: var(--color-black);
		font-size: 24px;

	}

	.navactive {
		@media (min-width:1201px) {
			color: var(--main-nav-font-color-hover) !important;
			background-color: var(--main-nav-bg-hover) !important;
		}
	}


	@media screen and (max-width: 1200px) {

		.top-nav {
			padding-top: 10px;
			padding-bottom: 10px;
			text-align: right;


			.input-search-unit {
				display: none;
			}
		}

		.header-logo {
			padding-left: 0px !important;
		}

		.nav-row {
			min-height: 60px !important;
		}

		.icon {
			display: block;
			bottom: 20px;
		}

		.dropdown-menu {
			position: relative !important;
			transform: none !important;
			width: 100%;
			border-top: none;
		}

		.main-menu {
			background-color: var(--nav-bg-color);
			padding: 5px 15px;
			z-index: 50;
			display: none;
			width: 100%;
			border-bottom: 1px solid #61847a;
		}

		.search-unit-ul {
			margin: 15px 0px 0px;

			.input-search-unit {
				.input-group {
					.form-control {
						@media (max-width: 574px) {
							height: 40px !important;
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: 768px) {

		.top-nav {
			text-align: center;

			.input-search-unit {
				display: none;
			}
		}

		.header-main {

			.header-logo {
				position: static;
				text-align: center;

			}

			.nav-row {
				min-height: 36px !important;
			}

			.icon {
				position: absolute;
				bottom: 0;
				right: 10px;
			}
		}

	}

	@media screen and (min-width: 1201px) {
		.main-menu {
			display: block !important;
		}
	}

	&.affix-top {
		border-bottom: var(--nav-border-bottom-affix-top);
		background-color: var(--nav-bg-color-affix-top);
		z-index: 4;

		.top-nav {
			background-color: var(--top-nav-bg-color-affix-top);

			.input-search-unit {
				@media (max-width:1200px) {
					display: none;
				}
			}

			ul {

				li {
					color: var(--top-nav-font-color-affix-top);

					@media (max-width:1100px) {
						display: block;
					}

					a {
						color: var(--top-nav-font-color-affix-top);
						font-size: var(--top-nav-font-size);
						text-decoration: none;
						padding: 5px 15px;

						@media (max-width:550px) {
							padding: 5px 4px;
						}

						&:hover {
							color: var(--top-nav-font-color-affix-top-hover);
						}

					}
				}
			}
		}



		.header-main {

			.header-logo {

				.logo {}

			}

			.main-menu {

				>ul {
					padding: 0;
					margin: 0;

					>li {
						>a {
							/* color: var(--main-nav-font-color-affix-top); */

							@media (max-width: 1200px) {
								color: var(--color-white);
							}
						}

						&.dropdown {

							cursor: pointer;

							.dropdown-backdrop {
								display: none;
							}


							&.show {
								background-color: var(--main-nav-bg-hover);

								a {
									color: var(--main-nav-font-color-hover);
								}
							}
						}




					}
				}

				.btn {
					@media (max-width:340px) {
						font-size: 12px;
						padding: 8px 8px;
					}
				}
			}
		}
	}

}

.mobil-menu {
	position: fixed;
	cursor: pointer;
	display: none;
	bottom: 20px;
	right: 5px;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background-color: #fff;
	z-index: 200;
	line-height: 40px;
	text-align: center;
	font-size: 24px;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	-webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	-o-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

	@media (min-width:1101px) {
		display: none !important;
	}
}

.open-by-mob {
	#mob-nav-icon {
		display: none;
	}

}