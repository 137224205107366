.static-v1 {
	margin-top: var(--page-margin-top);

	ul {
		list-style: disc;
		padding-left: 30px;
	}




}